import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

import { Icon } from './Icon'

export interface Props {
  description?: string
  isExpanded?: boolean
  label?: string
  type?: string
}

export const Item = memo(function Item({ description, label, type }: Props) {
  if (!label) {
    return null
  }

  return (
    <Container activeClassName="active">
      <Head>
        <Title>
          {type ? <Icon type={type} /> : null}

          {label}
        </Title>
      </Head>

      {description ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Description
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)``

const Head = styled(AccordionItemHeading)`
  cursor: pointer;
`

const Title = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  letter-spacing: 0.0938rem;
  line-height: 1.1875rem;
  padding: 1.25rem 1.875rem;
  text-transform: uppercase;
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  font-size: 1.0625rem;
  line-height: 2rem;
  overflow: hidden;
  padding: 0 1.875rem;
`

const Description = styled.div`
  padding-bottom: 1.875rem;
`
