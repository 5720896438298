import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Bottom } from './Bottom'

export interface Props {
  description?: string
  directionsLabel?: string
  directionsTitle?: string
  googleMapsURL?: string
  image?: ImageProps
  label?: string
  languageCode: string
  list?: string[]
  title?: string
}

export const Directions = memo(function Directions({
  description,
  directionsLabel,
  directionsTitle,
  googleMapsURL,
  image,
  label,
  languageCode,
  list,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Head>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}

          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}

          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Head>

        {image ? (
          <Background>
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          </Background>
        ) : null}

        {googleMapsURL ? (
          <CTA
            label={useVocabularyData('open-in-google-maps', languageCode)}
            rel="noopener"
            target="_blank"
            URL={googleMapsURL}
          />
        ) : null}
      </Wrapper>

      <Bottom label={directionsLabel} list={list} title={directionsTitle} />
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
`

const Wrapper = styled.div`
  padding: 8.375rem 0 53vh 0;
  position: relative;

  @media (max-width: 1199px) {
    padding: 0;
    text-align: center;
  }
`

const Head = styled.div`
  max-width: 52.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  margin: auto;
  padding: 6.5rem 7.8125rem;
  position: relative;
  text-align: center;
  z-index: 3;

  @media (max-width: 1199px) {
    max-width: none;
    padding: 3.75rem 1.5rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.25rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 1.875rem;
`

const Background = styled.div`
  width: 100%;
  height: 77.8vh;
  max-height: 43.75rem;
  position: absolute;
  top: 22.125rem;
  left: 0;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    height: 20rem;
    position: relative;
    top: auto;
    left: auto;
  }
`

const CTA = styled(Button)`
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  position: absolute;
  bottom: 4.375rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralLight5};
  }

  @media (max-width: 1199px) {
    bottom: 0;
    right: 0;
    left: 0;
    transform: none;
  }
`
