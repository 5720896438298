import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  type: string
}

export const Icon = memo(function Icon({ type }: Props) {
  const SVG = () => {
    switch (type) {
      case 'train':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.488"
            height="30"
            viewBox="0 0 20.488 30"
          >
            <path
              d="M-14980.184-2540.371l-1.174-2.893h-15.828l-1.174,2.893a.6.6,0,0,1-.557.375.6.6,0,0,1-.226-.044h0a.6.6,0,0,1-.33-.781l2.34-5.767a2.254,2.254,0,0,1-1.764-2.2v-18.957a2.256,2.256,0,0,1,2.253-2.253h14.742a2.257,2.257,0,0,1,2.254,2.253v18.957a2.256,2.256,0,0,1-1.765,2.2l1.011,2.491h0l1.329,3.276a.6.6,0,0,1-.331.781.591.591,0,0,1-.225.044A.6.6,0,0,1-14980.184-2540.371Zm-16.516-4.093h14.854l-.839-2.068h-13.176Zm.057-3.268h14.742a1.055,1.055,0,0,0,1.054-1.053v-4.717a5.4,5.4,0,0,1-4.211,2.02h-8.428a5.4,5.4,0,0,1-4.211-2.02v4.717h0A1.055,1.055,0,0,0-14996.643-2547.732Zm-1.053-9.16a4.215,4.215,0,0,0,4.21,4.21h8.429a4.215,4.215,0,0,0,4.21-4.21v-7.447h-16.85Zm0-10.85v2.2h16.85v-2.2a1.055,1.055,0,0,0-1.054-1.053h-14.742A1.055,1.055,0,0,0-14997.7-2567.743Zm12.975,18.736a.6.6,0,0,1-.6-.6.6.6,0,0,1,.6-.6h1.382a.6.6,0,0,1,.6.6.6.6,0,0,1-.6.6Zm-10.481,0a.6.6,0,0,1-.6-.6.6.6,0,0,1,.6-.6h1.383a.6.6,0,0,1,.6.6.6.6,0,0,1-.6.6Z"
              transform="translate(14999.516 2569.996)"
            />
          </svg>
        )
      case 'plane':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.722"
            height="24"
            viewBox="0 0 23.722 24"
          >
            <path
              d="M156.592,89.6a.477.477,0,0,1-.108-.012.47.47,0,0,1-.339-.31l-3.479-10.561-3.6,3.638V87.18a.47.47,0,0,1-.136.331l-1.85,1.873a.47.47,0,0,1-.756-.122l-2.636-5.335-5.272-2.666a.47.47,0,0,1-.122-.75l1.85-1.871a.469.469,0,0,1,.334-.139h4.763l3.605-3.648L138.4,71.326a.47.47,0,0,1-.184-.776l1.947-1.969a.473.473,0,0,1,.377-.138l13.419,1.235,2.165-2.191c.314-.316,1.954-1.887,3.531-1.887a2.111,2.111,0,0,1,1.536.634c1.316,1.331.326,3.525-1.24,5.11l-2.168,2.193,1.22,13.581a.471.471,0,0,1-.134.372l-1.946,1.97a.474.474,0,0,1-.335.139Zm-3.72-12.231a.477.477,0,0,1,.108.012.47.47,0,0,1,.339.31l3.48,10.56,1.252-1.267L156.829,73.4a.47.47,0,0,1,.134-.372l2.321-2.348c.862-.872,2.23-2.787,1.24-3.788a1.174,1.174,0,0,0-.867-.354,5.131,5.131,0,0,0-2.863,1.609L154.474,70.5a.466.466,0,0,1-.377.138L140.678,69.4l-1.261,1.275L149.864,74.2a.47.47,0,0,1,.184.776L145.776,79.3a.47.47,0,0,1-.334.139h-4.763l-1.259,1.273,4.836,2.446a.471.471,0,0,1,.209.212l2.413,4.885,1.251-1.266V82.162a.469.469,0,0,1,.136-.331l4.272-4.322a.469.469,0,0,1,.334-.139Z"
              transform="translate(-138.083 -65.601)"
            />
          </svg>
        )

      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="24.793"
            viewBox="0 0 30 24.793"
          >
            <path
              d="M-15418.661-2024.819a1.51,1.51,0,0,1-1.507-1.508v-1.535h-12.7v1.535a1.51,1.51,0,0,1-1.508,1.508h-3.006a1.51,1.51,0,0,1-1.508-1.508v-1.594a3.213,3.213,0,0,1-2.63-3.153v-3.777a3.212,3.212,0,0,1,3.185-3.209l1.134-5.806a3.007,3.007,0,0,1,2.945-2.425h2.362l.8-2.883a.6.6,0,0,1,.578-.439h7.981a.6.6,0,0,1,.578.439l.8,2.883h2.362a3.006,3.006,0,0,1,2.944,2.425l1.135,5.806h0a3.213,3.213,0,0,1,3.186,3.209v3.777a3.213,3.213,0,0,1-2.63,3.153v1.594a1.51,1.51,0,0,1-1.509,1.508Zm-.308-1.509a.309.309,0,0,0,.308.308h3.006a.309.309,0,0,0,.309-.308v-1.535h-3.622Zm-18.718,0a.309.309,0,0,0,.308.308h3.006a.308.308,0,0,0,.308-.308v-1.535h-3.621Zm-2.63-8.523v3.777a2.013,2.013,0,0,0,2.011,2.012h23.578a2.014,2.014,0,0,0,2.011-2.012v-3.777a2.014,2.014,0,0,0-2.011-2.012h-23.578A2.013,2.013,0,0,0-15440.316-2034.851Zm4.3-8.785-1.089,5.573h21.184l-1.09-5.573a1.8,1.8,0,0,0-1.766-1.455h-15.473A1.8,1.8,0,0,0-15436.02-2043.636Zm5.375-2.655h8.255l-.592-2.121h-7.071Zm10.218,13.328a2.484,2.484,0,0,1,2.481-2.481,2.484,2.484,0,0,1,2.48,2.481,2.484,2.484,0,0,1-2.48,2.481A2.484,2.484,0,0,1-15420.427-2032.962Zm1.2,0a1.282,1.282,0,0,0,1.281,1.281,1.282,1.282,0,0,0,1.28-1.281,1.282,1.282,0,0,0-1.28-1.281A1.282,1.282,0,0,0-15419.227-2032.962Zm-18.343,0a2.483,2.483,0,0,1,2.481-2.481,2.483,2.483,0,0,1,2.479,2.481,2.483,2.483,0,0,1-2.479,2.481A2.483,2.483,0,0,1-15437.569-2032.962Zm1.2,0a1.284,1.284,0,0,0,1.282,1.281,1.282,1.282,0,0,0,1.279-1.281,1.282,1.282,0,0,0-1.279-1.281A1.283,1.283,0,0,0-15436.37-2032.962Z"
              transform="translate(15441.517 2049.612)"
            />
          </svg>
        )
    }
  }

  return <Container dial={2}>{SVG()}</Container>
})

const Container = styled(FlexBox)`
  width: 1.875rem;
  max-width: 1.875rem;
  height: 1.875rem;
  margin-right: 1.125rem;

  svg {
    fill: ${({ theme }) => theme.colors.variants.neutralLight5};
  }
`
