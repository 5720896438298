import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Wave } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  label?: string
  list?: string[]
  title?: string
}

export const Bottom = memo(function Bottom({ label, list, title }: Props) {
  return (
    <Container row wrap>
      <LeftSide>
        {label ? (
          <FadeInUp>
            <Label dial={4} row>
              {label}
              <Wave />
            </Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
      </LeftSide>

      <RightSide>
        {list ? (
          <Accordion allowZeroExpanded>
            {list.map((item: any, index) => (
              <Item key={index} {...item} />
            ))}
          </Accordion>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 7.875rem 6.111vw;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.5rem;
  }
`

const LeftSide = styled.div`
  width: 50%;

  @media (max-width: 1199px) {
    width: 100%;
  }
`

const Label = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;

  svg {
    width: 3.5rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralLight5};
    margin-left: 1.25rem;
  }

  @media (max-width: 1199px) {
    align-items: flex-start;
    flex-direction: column-reverse;

    svg {
      margin-left: 0;
    }
  }
`

const Title = styled.h2`
  max-width: 20.625rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 4.25rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const RightSide = styled.div`
  width: 50%;

  .accordion {
    > div {
      border: 0.0625rem solid ${rgba(theme.colors.variants.neutralLight5, 0.1)};
      color: ${({ theme }) => theme.colors.variants.neutralLight5};
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      margin-top: -0.0625rem;
      transition: 0.5s ease-in-out;
      &.active {
        background: ${({ theme }) => theme.colors.variants.neutralLight5};
        color: ${({ theme }) => theme.colors.variants.neutralDark2};

        svg {
          fill: ${({ theme }) => theme.colors.variants.primaryDark};
        }
      }
    }
  }

  @media (max-width: 1199px) {
    width: 100%;

    .accordion {
      margin-top: 2.5rem;
    }
  }
`
